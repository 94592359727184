.HomeLayoutUpdate {
    max-width: 700px;
    margin: auto;
    min-height: 100vh;
    background: #f9f9f9;
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .proHeading {
        background: #006D00;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        h1 {
            color: #fff;
            font-weight: 700;
            font-size: 40px;
        }
    }
    .checkboxes {
        .chiller_cb2 {
            cursor: pointer;
            input {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
            .yesOne {
                font-size: 15px;
                position: relative;
                top: -5px;
                font-weight: 500;
            }
        }
    }
    .repeatList {
        text-align: center;
        padding-bottom: 30px;
        justify-content: center;
        .lists {
            img {
                width: 100%;
            }
        }
        h5 {
            color: #999;
            padding-top: 7px;
            font-size: 16px;
        }
    }
    .listDetails {
        position: relative;
        text-align: center;
        justify-content: center;
        margin-bottom: 30px;
        h2 {
        }
        h3 {
            font-size: 24px;

            &.fsmanages {
            }
        }
        h4 {
            color: #999;
            font-weight: 400;
            font-size: 22px;
            margin-bottom: 5px;
        }
        h5 {
            color: #999;
            font-weight: 400;
            margin-bottom: 5px;
        }
    }
}

#lists img {
    height: 90px;
}

@media (max-width: 76px) {
    .HomeLayoutUpdate {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {    
    line-height: 1;
}