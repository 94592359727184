.Checkouts {
    max-width: 700px;
    margin: auto;
    min-height: 100vh;
    background: #f9f9f9;
    &.layTwo {
        max-width: 600px;
    }

    .repeatList {
        text-align: center;
        padding-bottom: 30px;
        justify-content: center;
        .lists {
            img {
                width: 100%;
            }
        }
        h5 {
            color: #999;
        }
    }
    .btnSetone {
        position: relative;
        button.btn-yellows {
            background: #f8bb00;
            display: block;
            width: 100%;
            max-width: 65%;
            margin: auto;
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            outline: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .orderProOne {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        h4 {
            text-align: left;
            font-size: 14px;
            color: #666;
        }
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
        sup {
            top: -15px;
            left: -10px;
            font-weight: 500;
            /* color: #999; */
            margin-top: 5px;
            background: white;
            width: 20px;
            height: 20px;
            font-weight: 700;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
        }
    }
    .productListNew {
        padding: 0 20px;
        h5 {
            text-align: left;
            font-size: 15px;
        }
        .dataListing {
            .row {
                margin-bottom: 15px;
            }
            h4 {
                text-align: right;
                font-size: 14px;
                color: #666;
            }
        }
    }
    .borderBot {
        width: 100%;
        height: 1px;
        background: #ddd;
        text-align: center;
        margin-bottom: 15px;
    }
    .listWatch {
        position: relative;
        text-align: center;
        justify-content: center;
        margin-bottom: 30px;
        h1 {
            margin-top: 70px;
            color: #404040;
            font-weight: 300;
            text-transform: none;
            margin-bottom: 15px;
        }
        h6 {
            color: grey;
            margin-bottom: 12px;
        }
        h3 {
            font-size: 24px;

            &.fsmanages {
            }
        }
        h4 {
            color: #999;
            font-weight: 400;
            font-size: 22px;
            margin-bottom: 5px;
        }
    }
}

@media (max-width: 767px) {
    .HomeLayoutUpdate {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .listWatch {
            padding: 0 15px;
        }
        .btnSetone {
            button.btn-yellows {
                max-width: 100%;
            }
        }
        .productListNew {
            .dataListing {
                h4 {
                    margin-top: 10px;
                }
            }
        }
    }
}

.googleModes {
    background: rgba(0, 0, 0, 0.87);
}

.googleModes {
    position: relative;
    .modal-dialog {
        max-width: 750px;
    }
    .modal-header {
        border-bottom: none;
    }
    .modal-content {
        background: transparent;
        border: none;
    }
    .modal-body {
        background: transparent;
        .imgSet {
            & > img {
                width: 100%;
            }
        }
    }
    .modal-footer {
        border-top: none;
        padding: 0;
        width: 100%;
        justify-content: center;
        .btnMains {
            cursor: pointer;
            position: relative;
            background: #fff;
            display: block;
            margin: 0;
            width: 100%;
            max-width: 72%;
            margin-left: -40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 115px;
            border-radius: 10px;
            a {
                display: block;
                height: 100%;
                width: 100%;
                text-decoration: none;
            }
            a.btnSaves {
                img {
                    width: 170px;
                    margin-top: 18px;
                }
                .pre-hunt {
                    margin-top: 35px;
                }
            }
        }
    }
}

.modal.fade {
    transition: opacity 0.25s linear;
}

@media (max-width: 767px) {
    .HomeLayoutUpdate {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .listWatch {
            padding: 0 15px;
        }
        .btnSetone {
            button.btn-yellows {
                max-width: 100%;
            }
        }
        .productListNew {
            .dataListing {
                h4 {
                    margin-top: 10px;
                }
            }
        }
    }

    .modal-footer {
        .btnMains {
            margin-left: 0;
        }
    }
}
.video{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }